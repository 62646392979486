import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { NAV_SPLIT_TREATMENTS } from '../../../constants/strings/split';
import { SPLIT_TREATMENTS as DASHBOARD_SPLIT_TREATMENTS } from '../../../modules/dashboard/constants/splits';
import { State as TreatmentState } from '../../../test-utils/split';
import { Admin } from '../../../types';
import { SPLIT_TREATMENTS } from '../components/communication/conversations/constants/splits';
import { KEY_METRICS, MEMBER_BANNER_TREATMENT, MEMBER_INDICATION_TAB, MEMBER_STRIKE_THROUGH_AUDIT_HISTORY, SIMPLIFIED_WORKFLOWS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
export const getAdminUuid = (adminProfile: Admin): string => adminProfile.uuid || '*';
export const useSplitTreatments = () => {
  const $ = _c(11);
  const {
    adminProfile
  } = useShellContext();
  const splitClient = useClient();
  const adminUuid = getAdminUuid(adminProfile);
  const memberStrikeThroughAuditHistoryTreatment = splitClient?.getTreatment(MEMBER_STRIKE_THROUGH_AUDIT_HISTORY, {
    adminUuid
  }) === TreatmentState.On;
  const conversationInCommsPanelTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.CONVERSATIONS_IN_COMMS_PANEL, {
    adminUuid
  }) === TreatmentState.On;
  const memberBannerTreatment = splitClient?.getTreatment(MEMBER_BANNER_TREATMENT, {
    adminUuid
  }) === TreatmentState.On;
  const memberIndicationTab = splitClient?.getTreatment(MEMBER_INDICATION_TAB, {
    adminUuid
  }) === TreatmentState.On;
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid
  }) === TreatmentState.On;
  const keyMetricsTreatment = splitClient?.getTreatment(KEY_METRICS, {
    adminUuid
  }) === TreatmentState.On;
  const modularIamPainInsightsTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.MODULAR_IAM_PAIN_INSIGHTS, {
    adminUuid
  }) === TreatmentState.On;
  const coachVideoVisitTreatment = splitClient?.getTreatment(DASHBOARD_SPLIT_TREATMENTS.COACH_VIDEO_VISIT, {
    adminUuid
  }) === TreatmentState.On;
  const enableWorkflowStateFilteringTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING, {
    adminUuid
  }) === TreatmentState.On;
  const videoVisitRecordingPlaybackTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.VIDEO_VISIT_RECORDING_PLAYBACK, {
    adminUuid
  }) === TreatmentState.On;
  let t0;
  if ($[0] !== conversationInCommsPanelTreatment || $[1] !== memberBannerTreatment || $[2] !== memberIndicationTab || $[3] !== isSimplifiedWorkflowsSplitOn || $[4] !== keyMetricsTreatment || $[5] !== modularIamPainInsightsTreatment || $[6] !== coachVideoVisitTreatment || $[7] !== memberStrikeThroughAuditHistoryTreatment || $[8] !== enableWorkflowStateFilteringTreatment || $[9] !== videoVisitRecordingPlaybackTreatment) {
    t0 = {
      conversationInCommsPanelTreatment,
      memberBannerTreatment,
      memberIndicationTab,
      isSimplifiedWorkflowsSplitOn,
      keyMetricsTreatment,
      modularIamPainInsightsTreatment,
      coachVideoVisitTreatment,
      memberStrikeThroughAuditHistoryTreatment,
      enableWorkflowStateFilteringTreatment,
      videoVisitRecordingPlaybackTreatment
    };
    $[0] = conversationInCommsPanelTreatment;
    $[1] = memberBannerTreatment;
    $[2] = memberIndicationTab;
    $[3] = isSimplifiedWorkflowsSplitOn;
    $[4] = keyMetricsTreatment;
    $[5] = modularIamPainInsightsTreatment;
    $[6] = coachVideoVisitTreatment;
    $[7] = memberStrikeThroughAuditHistoryTreatment;
    $[8] = enableWorkflowStateFilteringTreatment;
    $[9] = videoVisitRecordingPlaybackTreatment;
    $[10] = t0;
  } else {
    t0 = $[10];
  }
  return t0;
};